<template>
    <div class="ag-card-container" :class="roundedTop">
        <slot>

        </slot>
    </div>
</template>

<script>
export default {
    name: 'AGCard',
    props: {
        hasRoundedTop: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        roundedTop() {
            return this.hasRoundedTop ? 'ag-card-rounded-top' : '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import './src/design/variables.scss';

.ag-card-container {
    padding: $spacing-inset-05;
    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
    border: $border-width-hairline $border-default $neutral-color-low-dark;
    background: $neutral-color-low-light;

    color: $neutral-color-high-dark;
}
.ag-card-rounded-top {
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: $border-radius-sm;
}
</style>